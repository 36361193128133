import { ACTION_TYPE, ROUTE_KEY_TYPE } from '@/shared/utils/constants/common.ts';

export const additionalRoutes = [
  {
    path: '/additional/accounts',
    name: 'additional-accounts',
    meta: {
      keyName: ROUTE_KEY_TYPE.ACCOUNTS,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () => import('@/pages/additional/accounts/AccountsPage.vue')
  },
  {
    path: '/additional/salary-payrolls',
    name: 'additional-salary-payrolls',
    meta: {
      keyName: 'PAYROLL_LIST'
    },
    component: () =>
      import('@/pages/additional/salary-payrolls/SalaryPayrollsListPage.vue')
  },
  {
    path: '/additional/salary-payrolls/create/:id',
    name: 'additional-salary-payrolls-create',
    meta: {
      keyName: 'PAYROLL_LIST',
      keyNameAction: 'ADD'
    },
    component: () =>
      import(
        '@/pages/additional/salary-payrolls/salary-payrolls-create/IndexPage.vue'
      )
  },
  {
    path: '/additional/salary-payrolls/create/copy/:id',
    name: 'additional-salary-payrolls-create-copy',
    meta: {
      keyName: 'PAYROLL_LIST',
      keyNameAction: 'ADD'
    },
    component: () =>
      import(
        '@/pages/additional/salary-payrolls/salary-payrolls-create-copy/IndexPage.vue'
      )
  },
  {
    path: '/additional/salary-payrolls/edit/:id',
    name: 'additional-salary-payrolls-edit',
    meta: {
      keyName: 'PAYROLL_LIST'
    },
    component: () =>
      import(
        '@/pages/additional/salary-payrolls/salary-payrolls-edit/IndexPage.vue'
      )
  },
  {
    path: '/additional/employees',
    name: 'additional-employees',
    meta: {
      keyName: ROUTE_KEY_TYPE.EMPLOYEES_LIST,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () => import('@/pages/additional/employees/EmployeesPage.vue')
  }
];
